import React from 'react'
import { CardHeader, CardMedia, Card, AlertTitle, Skeleton, ListItem, ListItemButton, ListItemText, Snackbar } from '@mui/material';
import {
  useParams,
  useHistory
} from "react-router-dom";
import moment from 'moment'

import pagedetail from '../../menulist/News.json'
import Typography from '@mui/material/Typography';

const NewsCom = ({item, i, dark, lang}) => {
  const [EnCache, setEnCache] = React.useState('');
    const [TextUpdate, setTextUpdate] = React.useState('');

    const removefonttag = (data) => {
        var div = document.createElement('div');

        // assing your HTML to div's innerHTML
        div.innerHTML = data;

        // get all <a> elements from div
        var elements = div.getElementsByTagName('font');

        // remove all <a> elements
        while (elements[0])
        elements[0].parentNode.removeChild(elements[0])

        // get div's innerHTML into a new variable
        var repl = div.innerHTML;
        return repl
    }


    // const AutomateTranslator = async (val) => {
    //     if (lang == 'en') {
    
    //       var myHeaders = new Headers();
    //       myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          
    //       var urlencoded = new URLSearchParams();
    //       urlencoded.append("text_to_translate", val);
    //       urlencoded.append("source_lang", "th");
    //       urlencoded.append("translated_lang", "en");
    //       urlencoded.append("use_cache_only", "true");
          
    //       var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: urlencoded,
    //       };
          
    //       setTimeout(() => {
    //         fetch("https://www.translate.com/ajax/machine-translation/translate", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //           if (result.result == 'success') {
    //             setTextUpdate(result.translated_text)
    //             setEnCache(result.translated_text)
    //           } else {
    //             setTextUpdate(val)
    //           }
    //         })
    //         .catch(error => console.log('error', error));
    //       }, 400 * (i /5));
             
    //         } else {
    //           setTextUpdate(val)
    //         }
    //    }
     
    //    React.useEffect(() => {
    //      if (item != null) {
    //       if (lang == 'th') {
    //         AutomateTranslator(removefonttag(item.title).replaceAll(/<\/?[^>]+(>|$)/g, "").replaceAll("&nbsp;", " "))
    //       } else {
    //         if (EnCache != '') {
    //           setTextUpdate(EnCache)
    //         } else {
    //           AutomateTranslator(removefonttag(item.title).replaceAll(/<\/?[^>]+(>|$)/g, "").replaceAll("&nbsp;", " "))
    //         }
    //       }
    //      }
    //    }, [lang])

    React.useEffect(() => {
      if (item != null && lang == 'th') {
        setTextUpdate(removefonttag(item.title).replaceAll(/<\/?[^>]+(>|$)/g, "").replaceAll("&nbsp;", " "))
      }
    }, [lang])

    
    return ( 
        <ListItem data-aos="zoom-in" disablePadding>
                    <ListItemButton onClick={() => window.open(item.link, '_blank')}>
                    <ListItemText primary={
                        TextUpdate != '' ? (<Typography className={dark ? 'text-light' : ''} variant='h6' dangerouslySetInnerHTML={{ __html: TextUpdate}} />) : <Skeleton style={{backgroundColor: dark ? '#fff' : ''}} />}
                        secondary={(<p className={dark ? 'text-light' : ''}>{pagedetail[lang].obj.by + item.source["#text"] + pagedetail[lang].obj.provide + moment.utc(new Date(item.pubDate).toISOString()).local().locale(lang).format("DD MMMM YYYY HH:mm")}</p>)} 
                    />
                    </ListItemButton>
                </ListItem>
     );
}
 
export default NewsCom;