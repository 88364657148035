import React from 'react'
import { Toolbar, AppBar, Accordion, AccordionSummary, AccordionDetails, Card, CardContent,Typography, Dialog, Backdrop, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab, TextField, CardHeader } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment'
import 'moment/locale/th'  // without this line it didn't work
import {
  useParams,
  useHistory
} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Swal from 'sweetalert2';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from '@mui/icons-material/Share';

const numberWithCommasx = (x) => {
  return parseInt(x).toLocaleString('en-US');
}

var load = false;

const ViewComment = ({onClose, livevideoobj, lang, dark}) => {
  const History = useHistory();
  const [comment, setCom] = React.useState(null);
  const [tog, setTog] = React.useState(false);

  const [comin, setCurrentCom] = React.useState(-1);

const api = () => [
  fetch('https://cpxdevweb.onrender.com/tpop/getstreamcomment?id=' + livevideoobj.id , {
    method :'post'
})
    .then(response => response.json())
    .then(data => {

      if (load == false) {
        load  = (true)
        if (data.view != "") {
          setTog(false)
        } else {
          setTog(true)
        }
      }

      const newData = {
        view: data.view,
        comments: data.comments.filter(x => !x.snippet.textMessageDetails.messageText.includes('::'))
      }
      setCom(newData)

      setCurrentCom(Math.floor(Math.random() * newData.comments.length))
})
]


  React.useEffect(() => {
    api()
    setInterval(() => {
      api()
    }, 8000)
  }, [])

    return ( 
        <>
        <AppBar sx={{ position: 'relative' }} className='forcebgPrimary'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {livevideoobj.snippet.title}
                          </Typography>
           
          </Toolbar>
        </AppBar>
        <div className='container mt-2 mb-5'>
            <iframe src={"https://www.youtube.com/embed/" + livevideoobj.id + "?mute=1"} allowFullScreen className='w-100' height={window.innerWidth > 700 ? 800 : 200}></iframe>
            <Card>
            {
                  comment != null && comment.view != "" && comment.view != "0" && comin > -1 && (
                    <CardContent className='text-left align-start' sx={{backgroundColor: dark ? '#bab8b8' : ''}}>
                      <CardHeader title={<div className={dark ? 'text-dark h5': 'h5'} dangerouslySetInnerHTML={{ __html: comment.comments[comin].snippet.displayMessage }}></div>} subheader={(<p className={dark ? 'text-dark': ''}>{(lang == 'th' ? 'คอมเม้นบนยูทูปเมื่อวันที่ ' : 'Comment from Youtube user since ') + moment(comment.comments[comin].snippet.publishedAt).local().format('DD MMMM YYYY HH:mm:ss')}</p>)} />
                  </CardContent>
                  )
                }
            </Card>
           {
           comment != null && comment.view != "" && comin > -1 ? (
              <Accordion className={dark ? 'text-light': ''} expanded={tog} onChange={() => setTog(!tog)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{backgroundColor: dark ? '#1976d2' : ''}}
              >
                <CardHeader title={<h5>{lang == 'th' ? 'รายละเอียดไลฟ์' : 'LIVE Description'}</h5>} subheader={comment.view != "0" ? (<p style={{color: dark ? '#a8a8a8': ''}}>{(lang == 'th' ? 'จำนวนผู้ชมในขณะนี้ ' : 'Current viewers are ') + numberWithCommasx(comment.view) + (lang == 'th' ? ' ครั้ง' : ' view(s)') }</p>) : (<p style={{color: dark ? '#a8a8a8': ''}}>{(lang == 'th' ? 'ไลฟ์นี้อยู่ระหว่างเตรียมความพร้อมของระบบ กรุณารอสักครู่' : 'This LIVE Streaming is preparing. Sit back, relax and enjoy this LIVE streaming soon!')}</p>)} />
                
              </AccordionSummary>
              <AccordionDetails sx={{backgroundColor: dark ? '#bab8b8' : ''}}>
                              <Typography paragraph className={'nl text-wrap' + (dark ? ' text-dark' : '')}>{livevideoobj.snippet.description}</Typography>
              </AccordionDetails>
            </Accordion>
            ) : (
              <Accordion className={dark ? 'text-light': ''} expanded={tog}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                sx={{backgroundColor: dark ? '#1976d2' : ''}}
              >
                <CardHeader title={<h5>{lang == 'th' ? 'รายละเอียดไลฟ์' : 'LIVE Description'}</h5>} />
                
              </AccordionSummary>
              <AccordionDetails sx={{backgroundColor: dark ? '#bab8b8' : ''}}>
                              <Typography paragraph className={'nl text-wrap' + (dark ? ' text-dark' : '')}>{livevideoobj.snippet.description}</Typography>
              </AccordionDetails>
            </Accordion>
            )
           }
       </div>
       <Backdrop
        sx={{ zIndex: -1, position: 'fixed', backgroundColor: (dark ? "#02012b" : "#fff")}}
        open={livevideoobj.snippet.liveBroadcastContent != 'live' ? true : false}
        >
        {lang == 'th' ? 'ไม่พบการถ่ายทอดสดในขณะนี้' : 'Sorry, Could not be found any Live streaming'}
      </Backdrop>
       </>
     );
}
 
export default ViewComment;
