import React from 'react'
import pagedetail from '../menulist/About.json'
// import Typography from '@mui/material/Typography';
// import { CardHeader, CardMedia, Card, CardActionArea, CardContent, List, ListItem, ListItemText, Grow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, CardActionArea, CardContent, List, ListItem, ListItemText, Grow } from '@mui/material';
import {
  useParams,
  useHistory
} from "react-router-dom";

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

const About = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    // const [langselect, setLang] = React.useState('en');
    const History = useHistory();

    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      sessionStorage.removeItem('artlistprevious')
      if (lang == 'th') {
        setPage('เกี่ยวกับ')
      } else {
        setPage('About')
      }
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.onrender.com/tpop/status')
        .then((response) => response.text())
        .then((data) => {
          setLoad(false)
        });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);
    
    // React.useEffect(() => {
    //   setLang(lang)
    // }, [lang]);

    if (load) return null
    return ( 
      <>
      <CardHeader className={dark ? 'text-light' : ''} title={pagedetail[lang].title} />
        <Typography className={'indent mt-4' + (dark ? ' text-light' : '')} dangerouslySetInnerHTML={{ __html: pagedetail[lang].desc }}>
        </Typography>
        <div className='mt-4'>
        <List>
                    <ListItem>
                        <ListItemText primary={(<h5 className={dark ? 'text-light' : ''}>{pagedetail[lang].list[0].title}</h5>)} />
                    </ListItem>
                </List>
        {
            pagedetail[lang].list.map((item, i) => i > 0 && (
                <List key={item.title}>
                    <ListItem>
                        <ListItemText primary={<p className={dark ? 'text-light' : ''}>{item.title}</p>} secondary={<p className={dark ? 'text-light' : ''}>{item.desc}</p>} />
                    </ListItem>
                </List>
            ))
        }
        </div>
        <div className={'container mt-5 text-center ' + (dark ? 'text-light' : '')}>
          <CardHeader title={lang == 'th' ? 'ผู้ให้บริการโครงสร้างพื้นฐานของระบบ' : 'Infrastructure Provider'} subheader={(<p className={dark ? 'text-light' : ''}>{lang == 'th' ? 'เนื่องจากเราไม่สามารถพัฒนาแพลตฟอร์มได้ทั้งระบบในระยะเวลาสั้นๆ พวกเขาเหล่านี้จึงเป็นผู้ดูแลโครงสร้างของระบบเราเพื่อให้แพลตฟอร์มนี้ทำงานได้ราบรื่นและการจัดการง่ายขึ้น' : 'Because our teams cannot be develop and support on whole of platform infrastructure in shortterm. So these are supporter for benefit of system infrastructure to help our platform always avaliable and stabillity.'}</p>)} />
          <img src='https://d3hhrps04devi8.cloudfront.net/main/cpxdev.png' className={dark ? 'rounded' : ''} width="100%" style={{backgroundColor: dark ? '#fff' : ''}} />
        </div>
      </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(About);