import React from 'react'
import {
  useParams,
  useHistory
} from "react-router-dom";
import { CardHeader, Card, Backdrop, Button,Skeleton, CardActions, CardMedia, CardActionArea } from '@mui/material';
import { Carousel as MobileCarousel } from 'react-responsive-carousel';

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const blocked = [
  "TpopConcertFest"
]

const TStage = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage, login}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    const [rootArr, setRootArr] = React.useState(null);
    const [instantview, setInstantview] = React.useState([]);
    const History = useHistory();

    const [ix, setIx] = React.useState(0)
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (lang == 'th') {
        setPage('เพลย์ลิสต์ T-POP ประจำสัปดาห์')
      } else {
        setPage('T-POP Stage Weekly Playlist')
      }
      window.addEventListener('resize', handleWindowResize);
      
      setTimeout(() => {
        fetch('https://cpxdevweb.onrender.com/tpop/instantlistartists', {
            method: 'post'
        })
            .then((response) => response.json())
            .then((data) => {
            if (data.length > 0) {
                setInstantview(data)
            }
            });
      }, 300);
      
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const getLink = (spIdfrommain) => {
      let link = ""
      try {
        if (instantview.length > 0 && instantview.filter(x => x.spotID == spIdfrommain).length > 0) {
          link = instantview.filter(x => x.spotID == spIdfrommain)[0].artId
      }
      } catch {

      }
      
      return link
  }

    React.useEffect(() => {
        if (login != null) {
          setLoad(true)
          fetch('https://cpxdevweb.onrender.com/tpop/tpopstageplaylist', {
              method: 'post'
            })
              .then((response) => response.json())
              .then((data) => {
                if (data != null) {
                  setRootArr(data.res)
                }
                
                setTimeout(() => {
                  setLoad(false)
                }, 100);
              });
        } else { 
          setLoad(false)
          setRootArr([])
          setIx(0)
        }
      }, [login]);

    React.useEffect(() => {
      const current = document.documentElement.scrollTop
      window.scrollTo(0, document.documentElement.scrollHeight);
      window.scrollTo(0, 0);
      window.scrollTo(0, current);
    }, [dark])

    if (load) return null

    if (login == null) {
        return (
                <Backdrop
                sx={{ zIndex: -1, position: 'fixed', backgroundColor: (dark ? "#02012b":"#fff") }}
                open={true}
                className={(dark ? 'text-light' : '') + (width < 800 ? ' pt-5' : '')}
                >
                {lang == 'th' ? 'สิทธิพิเศษสำหรับผู้ที่เป็นสมาชิกเท่านั้น' : 'Exclusive for Fan Space Membership only!'}
                </Backdrop>
        )
    }

    return ( 
        <>
          <div className='text-center'>
            {
              dark ? (
            <Card className='text-left'>
               <CardHeader title={lang == 'th' ? 'เพลย์ลิสต์รายสัปดาห์' : 'Weekly Playlist'} subheader={lang == 'th' ? 'รวมเพลงแนะนำจากรายการ T-POP Stage Show ในสัปดาห์นี้ ทางช่องเวิร์คพอยท์ (อัปเดตทุกสัปดาห์)' : 'All songs from this week showtime of T-POP Stage Show in Workpoint TV (Update weekly)'} />
            </Card>
              ) : (
              <div className='text-left'>
                <CardHeader title={lang == 'th' ? 'เพลย์ลิสต์รายสัปดาห์' : 'Weekly Playlist'} subheader={lang == 'th' ? 'รวมเพลงแนะนำจากรายการ T-POP Stage Show ในสัปดาห์นี้ ทางช่องเวิร์คพอยท์ (อัปเดตทุกสัปดาห์)' : 'All songs from this week showtime of T-POP Stage Show in Workpoint TV (Update weekly)'} />
            </div>
              )
            }


<div>
  {rootArr != null && (
        <MobileCarousel autoPlay centerMode centerSlidePercentage={rootArr.length > 0 ? (width < 800 ? 100 : 30) : 100} infiniteLoop showArrows showIndicators={false} swipeable={true} showStatus={false} interval={8000} onChange={(e) => setIx(e)}>
            {rootArr.length > 0 ? rootArr.map((item, i) => (
                    <Card key={"home-" + item.track.id} data-tempid={item.track.id} className='m-2' sx={{backgroundColor: (dark ?'#02012b' : 'transperent')}}>
                        <CardActionArea className='cro-container'>
                        <CardMedia src={item.track.album.images[0].url} component="img" />
                        {
                            ix == i && (
                                <Card data-aos="fade-in">
                                    <CardHeader title={(<h4>{item.track.name}</h4>)} subheader={item.track.artists[0].name} />
                                    <CardActions>
                                      <Button className='bg-success text-light' size="large" onClick={() => window.open(item.track.external_urls.spotify, '_blank')}>{lang == 'th' ? 'ฟังเพลงนี้บน Spotify' : 'Listening on Spotify'}</Button>
                                      {
                                        getLink(item.track.artists[0].id) != '' && (
                                          <Button variant='outlined' onClick={() => History.push('/artist/' + getLink(item.track.artists[0].id))} size="small">{lang == 'th' ? 'ดูข้อมูลศิลปิน' : 'View artist info'}</Button>
                                        )
                                      }
                                    </CardActions>
                                </Card>
                            )
                        }
                        </CardActionArea>
                        </Card>
                )) : (
                  <Card className='m-2' sx={{backgroundColor: (dark ?'#fff' : 'transperent')}}>
                        <CardActionArea className='cro-container'>
                            <CardHeader title={(<h4>{lang == 'th' ? 'ระบบอยู่ระหว่างการอัปเดตเพลย์ลิสต์' :'We are updateing weekly playlist'}</h4>)} subheader={lang == 'th' ? 'เราจะกลับมาอีกครั้ง เร็วๆนี้' :'We will back soon'} />
                        </CardActionArea>
                        </Card>
                )}
          </MobileCarousel>
  )}
</div>
          </div>
        </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(TStage);
