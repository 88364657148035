import React from 'react'
import pagedetail from '../menulist/Status.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, AlertTitle, Alert, ListItem, ListItemButton, ListItemText, Snackbar } from '@mui/material';
import {
  useParams,
  useHistory
} from "react-router-dom";
import moment from 'moment'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';
 
mapboxgl.accessToken = 'pk.eyJ1IjoiY3B4dGgyMDE3IiwiYSI6ImNsZHY0MzN6bTBjNzEzcXJmamJtN3BsZ3AifQ.mYNwWaYKsiLeYXngFDtaWQ';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Status = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
  
    const [backend, setBackEnd] = React.useState(null);
    const [maindb, setMainDB] = React.useState(null);
    const [comdb, setComDB] = React.useState(null);
    const [nosql, setNosql] = React.useState(null);

    const [membershipservice, setMemService] = React.useState(null);
    const [communityservice, setComService] = React.useState(null);



    const mapContainer = React.useRef(null);
    const map = React.useRef(null);
    const [lng, setLng] = React.useState(100.523186);
    const [lat, setLat] = React.useState(13.736717);
    const [zoom, setZoom] = React.useState(3);

    const History = useHistory();
  
  
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (lang == 'th') {
        setPage('สถานะของระบบ')
      } else {
        setPage('System Status')
      }
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.onrender.com/tpop/checkstatus', {
        method: 'post'
      })
        .then((response) => response.json())
        .then((data) => {
          setMemService(2)
          setMainDB(true)
          if (data.switch[0].online == true && data.community == true) {
            setComService(2)
            setComDB(true)
          } else if (data.switch[0].online == false && data.community == true) {
            setComService(1)
            setComDB(true)
          } else {
            setComService(0)
            setComDB(false)
          }
          setNosql(true)
          setBackEnd(true)

          setLoad(false)
        }).catch(() => {
            setMemService(0)
            setComService(0)
            setMainDB(false)
            setNosql(false)
            setComDB(false)
            setBackEnd(false)

            setLoad(false)
        });

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    React.useEffect(() => {
      if (map.current) return; // initialize map only once
          map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [100.4935089, 13.7524938],
          zoom: 3,
          maxZoom: 4,
          minZoom: 0
          });
          
          fetch('https://cpxstatusservice.azurewebsites.net/api/systemstatus/cf')
          .then(response => response.json())
          .then((res) => {
            if (map.current != null) {
              for (let i=0; i< res.response.length; i++){
                const coodinate = [res.response[i].longitude, res.response[i].latitude]
                const popup = new mapboxgl.Popup()
                  .setText(res.response[i].title)
                  .addTo(map.current);
                popup.remove();
                new mapboxgl.Marker({ "color": "#34eb40" })
                  .setLngLat(coodinate).addTo(map.current).setPopup(popup);
              }
            }
          })
          .catch(() => {});
        }, []);

    return ( 
        <>
          <CardHeader title={(<h3 className={dark ? 'text-light' : ''}>{pagedetail[lang].title}</h3>)}  subheader={<p className={dark ? 'text-light' : ''}>{pagedetail[lang].desc}</p>} />
          <div className='text-center'>
            <Card>
            <div className='container col-12'>
              <div className='row d-flex justify-content-center'>
                <ListItem disablePadding className={backend == true ? 'bg-success' : 'bg-warning'}>
                    <ListItemButton>
                    <ListItemText primary={(<Typography variant='h6'>T-POP Megaverse and T-POP Community Backend API</Typography>)} secondary={lang == 'th' ? (backend == true ? 'สถานะปกติ' : 'อยู่ระหว่างการแก้ไข') : (backend == true ? 'Operational' : 'Partial Outage')} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding className={maindb == true ? 'bg-success' : 'bg-warning'}>
                    <ListItemButton>
                    <ListItemText primary={(<Typography variant='h6'>Database Engine (Membership)</Typography>)} secondary={lang == 'th' ? (maindb == true ? 'สถานะปกติ' : 'อยู่ระหว่างการแก้ไข') : (maindb == true ? 'Operational' : 'Partial Outage')} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding className={comdb == true ? 'bg-success' : 'bg-warning'}>
                    <ListItemButton>
                    <ListItemText primary={(<Typography variant='h6'>Database Engine (T-POP Community)</Typography>)} secondary={lang == 'th' ? (comdb == true ? 'สถานะปกติ' : 'อยู่ระหว่างการแก้ไข') : (comdb == true ? 'Operational' : 'Partial Outage')} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding className={nosql == true ? 'bg-success' : 'bg-warning'}>
                    <ListItemButton>
                    <ListItemText primary={(<Typography variant='h6'>Database Engine (MongoDB Server)</Typography>)} secondary={lang == 'th' ? (nosql == true ? 'สถานะปกติ' : 'อยู่ระหว่างการแก้ไข') : (nosql == true ? 'Operational' : 'Partial Outage')} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding className={membershipservice == 2 ? 'bg-success' : membershipservice == 1 ? 'bg-info' : 'bg-warning'}>
                    <ListItemButton>
                    <ListItemText primary={(<Typography variant='h6'>Membership Service</Typography>)} secondary={lang == 'th' ? (membershipservice == 2 ? 'สถานะปกติ' : membershipservice == 1 ? 'อยู่ระหว่างการปรับปรุง' : 'อยู่ระหว่างการแก้ไข') : (membershipservice == 2 ? 'Operational' : membershipservice == 1 ? 'Under maintenance' : 'Partial Outage')} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding className={communityservice == 2 ? 'bg-success' : communityservice == 1 ? 'bg-info' : 'bg-warning'}>
                    <ListItemButton>
                    <ListItemText primary={(<Typography variant='h6'>Community Service</Typography>)} secondary={lang == 'th' ? (communityservice == 2 ? 'สถานะปกติ' : communityservice == 1 ? 'อยู่ระหว่างการปรับปรุง' : 'อยู่ระหว่างการแก้ไข') : (communityservice == 2 ? 'Operational' : communityservice == 1 ? 'Under maintenance' : 'Partial Outage')} />
                    </ListItemButton>
                </ListItem>
              </div>
            </div>
            </Card>
          </div>
          <div className='mt-5 mb-5'>
          <CardHeader className={dark ? 'text-light' : ''} title='Micro Spot (CDN) of T-POP Megaverse Platform'
          subheader={lang == 'th' ? <p className={dark ? 'text-light' : ''}>{'Micro Spot เป็นการอาศัยคุณสมบัติของ CDN ช่วยเพิ่มประสิทธิภาพของระบบให้ดีขึ้น รวมทั้งลดผลจากการโจมตีทางไซเบอร์ไปยังระบบของเราโดยตรง ให้บริการโดย Cloudflare'}</p> : <p className={dark ? 'text-light' : ''}>{'Micro Spot use benefit of CDN to help enhance performance and reduce affected from cyber attack directly to our system. Provided by Cloudflare'}</p>} />
        <div ref={mapContainer} className="map-container" />
        <small className={dark ? 'text-light' : ''}>{lang == 'th' ? 'หมายเหตุ: ตำแหน่ง Micro Spot สังเกตได้จากมาร์คเกอร์สีเขียวบนแผนที่ สามารถตรวจสอบสถานะระบบ Micro Spot ได้' : 'Notes: You can see Micro Spot location by green marker on map. See Micro Spot system status '}<a href='https://www.cloudflarestatus.com/' target='_blank'>{lang == 'th' ? 'ที่นี่' : ' here'}</a></small>
        </div>
        </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Status);