import './App.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';


import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';


import ClickAwayListener from '@mui/material/ClickAwayListener';
import WarningIcon from '@mui/icons-material/Warning';

import TextField from '@mui/material/TextField';

import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import Swal from 'sweetalert2'

import {Backdrop, Collapse, FormControlLabel, Switch, Slide, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, ButtonGroup, CardActionArea, Badge} from '@mui/material';

import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import {
  useLocation,
  Route,
  Link,
  Switch as BasicSwitch,
  useHistory,
  Redirect
} from "react-router-dom";

import Home from './component/home';
import Art from './component/artists';
import ArtDetail from './component/artistDetail';
import News from './component/news';
import Live from './component/live';
import TopChart from './component/topchart';
import WeekPlay from './component/tpopstageweekly';
import StoryPlay from './component/tpopstoryplaylist';
import CommunityList from './component/community';
import CommunityDetail from './component/viewPostCommunity';
import About from './component/about';
import Contact from './component/contact';
import Regis from './component/register';
import Acct from './component/account';
import Status from './component/status';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from './redux/action';

import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
  OAuthProvider,
  deleteUser
} from "firebase/auth";
import auth from "./fbindex";
import "aos/dist/aos.css";
import 'sweetalert2/dist/sweetalert2.min.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Communitymain from './component/community';
import AOS from 'aos'

import moment from 'moment';
import 'moment-timezone';

let los
const drawerWidth = 240;
const navItemsLink = ['', 'artists', 'news', 'live', 'songlist', 'weekplay', 'community', 'about', 'contact'];
const navItemsEn = ['Home', 'Artists', 'News', 'Live Event', 'Top Songs', 'Weekly Playlist', 'Community', 'About', 'Contact us'];
const navItemsTh = ['หน้าหลัก', 'ค้นหาศิลปิน', 'ข่าวสาร', 'กิจกรรมการถ่ายทอดสด', 'อันดับเพลงสูงสุด', 'เพลย์ลิสต์ประจำสัปดาห์', 'คอมมูนิตี้', 'เกี่ยวกับ', 'ติดต่อเรา'];
const LangList = [{
  id:'en',
  label: 'English'
},{
  id:'th',
  label: 'ภาษาไทย'
}];
const settingsEn = ['Account', 'Logout'];
const settingsTh = ['ตั้งค่าบัญชี', 'ออกจากระบบ'];
const eventTime = 1673337600

const aa = true

function App({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}){
  // const [dark, setDark] = React.useState();
  const [con, setConnection] = React.useState(null);
  // const [load, setLoad] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [width, setRealwidth] = React.useState(window.innerWidth);
  // const [lang, setLang] = React.useState();
  const ref = React.useRef(null)
  const [footerHeight, setFooterH] = React.useState(0)
  // const [page, setPage] = React.useState('');
  const [greetall, setgreetall] = React.useState(true);
  const [time, setCurrentTime] = React.useState(moment.unix());

  const [footermore, setFootermore] = React.useState(false);

  const [login, setLogin] = React.useState(localStorage.getItem("tpoploged") != null ? JSON.parse(localStorage.getItem("tpoploged")) : null);

  const history = useHistory();

  const [grandfetch, setFetGrandcount] = React.useState(0);
  const [grandopen, setGrand] = React.useState(false);
  const [block, setBlock] = React.useState(false);

  const [done, setDone] = React.useState(false);
  const [offline, setOffline] = React.useState(false);

  const [verify, setVerify] = React.useState(true);

  const [logindialog, setLogindia] = React.useState(false);
  const [loginLoad, setLoginLoad] = React.useState(null);
  const win = useLocation()


  React.useEffect(() => {
    document.title = currentPage + " | T-POP Fans eX Platform"
  }, [currentPage])

  React.useEffect(() => {
    if (!load) {
        setDone(true)
    }
  }, [load])

 

 
 
  const testonline = () => {
    const newConnection = new HubConnectionBuilder()
    .withUrl("https://cpxdevweb.onrender.com/status")
    .build();

    setConnection(newConnection);
  }

  const loadcheck = () => {
    if (window.location.href.includes('localhost')) {
      setOffline(false)
    } else {
      con.start()
    .then(result => {
      con.on("responsestatus", function (res) {
        if (res =='ok') {
          setOffline(false)
        } else {
          setOffline(true)
        }
    });
    
   
    })
    .catch(e => {
      setOffline(true)
      setTimeout(() => {
        loadcheck()
      }, 5000)
    });

    }
  
  
  }


  React.useEffect(() => {
    if (con) {
      loadcheck()
      con.onclose(error => {
        setOffline(true)
        setTimeout(() => {
          loadcheck()
        }, 5000)
    });
    }
  }, [con]);

  function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
} 
  
  React.useEffect(() => {
    function handleWindowResize() {
      setRealwidth(window.innerWidth);
    }

    setDark(window.checkdarkmode())
    setLang(localStorage.getItem('tpoplang') != null ? localStorage.getItem('tpoplang') : 'en')

    AOS.init({ duration: 800 });
    window.addEventListener('resize', handleWindowResize);
    testonline()
   
    setGrand(true)

    fetch('https://cpxdevweb.onrender.com/tpop/time')
    .then((response) => response.text())
    .then((data) => {
      setCurrentTime(parseInt(data))
    }).catch(() => {
      setCurrentTime(moment.unix())
    });
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  React.useEffect(() => {
    if (ref.current != null){
      setFooterH(ref.current.clientHeight)
    } 
  })

const updateMem = () => {
  fetch('https://cpxdevweb.onrender.com/tpop/checklogin?i=' + JSON.parse(localStorage.getItem("tpoploged")).fromlogin.uid, {
        method :'post'
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == 0) {
          const log = {
            fromlogin: JSON.parse(localStorage.getItem("tpoploged")).fromlogin,
            fromsystem: data.response
          }
          setLogin(log)
          setVerify(data.response.verified)
          localStorage.setItem("tpoploged", JSON.stringify(log));
        }
      });
}

  React.useEffect(() => {
    if (localStorage.getItem("tpoploged") != null) {
      setLogin(JSON.parse(localStorage.getItem("tpoploged")))
      updateMem()
    }
  }, [])

  React.useEffect(() => {
    if (localStorage.getItem('tpoplang') != null) {
      setLang(localStorage.getItem('tpoplang'))
    } else {
      localStorage.setItem('tpoplang', lang)
    }
  }, [])
  



  React.useEffect(()=> {
    if (dark == true) {
      localStorage.setItem("dark", true)
    } else {
      localStorage.removeItem("dark")
    }
  },[dark])

  React.useEffect(() => {
    localStorage.setItem('tpoplang', lang)
  }, [lang]);



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const Signout = () => {
    setLogin(null)
    localStorage.removeItem("tpoploged")
  }

  function changeroute(page) {
    try {
      if (page != window.location.pathname) {
        setLoad(true)
        setTimeout(() => history.push(page), 500);
      }
    } catch {

    }
  }

  const memberOptionAct = (act) => {
    setAnchorElUser(null);
    switch(act){
      case 'Logout':
        Signout()
        break;
      case 'Account':
        changeroute('/membership')
        break;
      case 'status':
        changeroute('/status')
        break;
    }
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (dark) {
    document.body.style.backgroundColor = "#02012b";
  } else {
    document.body.style.backgroundColor = "";
  }

  if (offline) {
    document.title = (lang == 'th' ? 'กำลังเชื่อมต่อใหม่อีกครั้ง' : 'Reconnecting to server') + " | T-POP Fans eX Platform"
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", '#fc2003');
    return (
      <>
       <Backdrop
       sx={{ backgroundColor: 'rgba(255,255,255,0.4)', zIndex: 1501, position: 'fixed' }}
       open={offline}
       className='point'
       >
       <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
      <div className={dark ? 'text-light':''}>
        {lang == 'th' ? 'พบปัญหาการเชื่อมต่อ อาจเกิดจากการเชื่อมต่อขัดข้องหรือระบบมีปัญหาชั่วคราว กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตและรอสักครู่ หากดำเนินการดังกล่าวแล้วไม่ได้รับการแก้ไข กรุณาแจ้งปัญหาได้ที่ cpxdev@outlook.com' : 'Connection error, please check your internet connection and wait for moment. If issue is not been resolved, please contact us at cpxdev@outlook.com.'}
      </div>
       </Backdrop>
      </>
    )
  }

  const getGreeting = () => {
    const hrmm = parseInt(moment.unix(time).local().format("HHmm"))
    if (lang == 'th') {
      if (hrmm >= 600 && hrmm <= 1159) {
        return {
          header: "อรุณสวัสดิ์ครับ คุณ "
        } 
      }else if (hrmm >= 1200 && hrmm <= 1559) {
        return {
          header: "สวัสดียามบ่ายครับ คุณ "
        } 
      } else {
        return {
          header: "สวัสดีตอนเย็นครับ คุณ "
        } 
      }
    } else {
      if (hrmm >= 600 && hrmm <= 1159) {
        return {
          header: "Good morning, sir "
        } 
      }else if (hrmm >= 1200 && hrmm <= 1559) {
        return {
          header: "Good afternoon, sir. "
        } 
      } else {
        return {
          header: "Good evening, sir. "
        } 
      }
    }

  }


  const loginAction = (action) => {
    let provider = null
    switch (action) {
      case 1:
        provider = new GoogleAuthProvider();
        break;
      case 2:
        provider = new OAuthProvider("microsoft.com");
        break;
      case 3:
        provider = new OAuthProvider("yahoo.com");
        break;
      default:
        return;
    }
    setLoginLoad(true)
    signInWithPopup(auth, provider)
      .then((result) => {
        fetch('https://cpxdevweb.onrender.com/tpop/checklogin?i=' + result.user.uid  , {
          method :'post'
      })
        .then(response => response.json())
        .then(data => {
          setLoginLoad(false)
          if (data.status == 0) {
            const log = {
              fromlogin: result.user,
              fromsystem: data.response
            }
            localStorage.setItem("tpoploged", JSON.stringify(log));
            setLogindia(false)
            setLogin(log)
            setVerify(data.response.verified)
          } else if (data.status == 1) {
            // deleteUser(result.user)
            setLogindia(false)
            Swal.fire({
              title: 'User not found',
              text: lang == 'th' ? "บริการบัญชีนี้ยังไม่เคยถูกใช้งานในแพลตฟอร์ม Fan Space กรุณาลงทะเบียน" : "This user don't be register to our system. please try again.",
              icon: 'error'
            })
          } else if (data.status == 3) {
            setLogindia(false)
            Swal.fire({
              title: 'Membership System is under maintenance',
              text: lang == 'th' ? "อยู่ระหว่างการปรับปรุงระบบ คุณยังไม่สามารถเข้าสู่ระบบได้ในขณะนี้ ขออภัยในความไม่สะดวก" : "Membership is under maintenance. You cannot login right now. Sorry for inconvenience",
              icon: 'error'
            })
          } else {
            setLogindia(false)
            Swal.fire({
              title: 'System error',
              text: lang == 'th' ? "พบข้อผิดพลาดเนื่องในระบบ กรุณาแจ้งเจ้าหน้าที่" : "Something went worng with service. Please contact us for find solution.",
              icon: 'error'
            })
          }
        });
      })
      .catch((error) => {
        // Handle error.
        console.log('cirtical login', error)
        setLoad(false)
        setLogindia(false)
        Swal.fire({
          title: 'Login error or canceled by user',
          text: lang == 'th' ? "เพื่อรับสิทธิประโยชน์เฉพาะสมาชิก กรุณาล็อกอินเพื่อเข้าใช้งาน" : "For exclusive feature. You need to login Fan Space Membership.",
          icon: 'warning'
        })
      });
  }

 

 
  var metaThemeColor = document.querySelector("meta[name=theme-color]");
  metaThemeColor.setAttribute("content", dark ? 'gray' : 'rgb(25, 118, 210)');
  return (
    <Box>
    <Backdrop
      sx={{ backgroundColor: (dark ? '#02012b' : 'rgba(255,255,255,1)'), zIndex: 1700, position: 'fixed' }}
      open={load}
      transitionDuration={{ appear: done ? 300 : 0, enter: done ? 300 : 0, exit: 800 }}
      >
      <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
      </Backdrop>
      <Backdrop
        sx={{ zIndex: 2000, position: 'fixed' }}
        open={loginLoad}
        className='text-light'
        >
        <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
        {lang == 'th' ? 'กำลังเข้าสู่ระบบ กรุณารอสักครู่': 'Please wait for a moment'}
      </Backdrop>
      <Slide in={!load} direction="down">
      <AppBar component="nav" className='appbaredge' 
      style={dark == true ? {background: 'gray'} : {}}
      >
      {
       login != null && !verify && (
        <Slide in={login != null && !verify} direction="down" timeout={1500}>
            <Toolbar disableGutters onClick={() => changeroute('/membership')} className='bg-danger justify-content-center point'>
               <Typography>
          {lang == 'th' ? 'บัญชีผู้ใช้งานนี้ยังไม่ได้ยืนยันตัวตน คลิกที่นี่เพื่อยืนยันตัวตน' : 'This account has not been verify. Click here to continue'}
          </Typography>
         </Toolbar>
        </Slide>
          )
         }
        <Toolbar disableGutters sx={{justifyContent: 'space-between !important'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, ml:1, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, ml:2, display: { xs: 'none', sm: 'block' } }}
          >
            T-POP Fans eX
          </Typography>
          {width > 300 && width < 600 && (
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            T-POP Fans eX
          </Typography>
          )}
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            {(lang == 'en' ?navItemsEn:navItemsTh).map((item, i) => (
              <Button component={Link} onClick={() => changeroute('/' + navItemsLink[i])} key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, mr: 1 }} className='text-right'>
          <IconButton onClick={handleOpenUserMenu}>
          {login != null && !verify ? (
                  <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={(<WarningIcon className='text-warning' />)}
                >
                  <Avatar alt={login != null ? login.fromsystem.memUser : "U"} src={login != null && login.fromsystem.img} />
                </Badge>
                 ) : (
                  <Avatar alt={login != null ? login.fromsystem.memUser : "U"} src={login != null && login.fromsystem.img} />
                 )}
                 </IconButton>
            {/* <IconButton onClick={handleOpenUserMenu}>
              <Avatar alt={login != null ? login.fromsystem.memUser : "U"} src={login != null && login.fromsystem.img} />
            </IconButton> */}
            <Menu
              sx={{ mt: '45px', maxWidth: '450px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                  <Typography className='text-left text-wrap' textAlign="center">{login != null ? getGreeting().header : (lang == 'en' ?'Welcome back, ':'ยินดีต้อนรับครับ คุณ ')} {login != null ? login.fromsystem.memUser :"User"}</Typography>
                </MenuItem>
              <Divider />
              {
                login && !verify && (
                  <MenuItem onClick={() => changeroute('/membership')}>
                    <Typography textAlign="center">{lang == 'th' ? 'ยืนยันบัญชีผู้ใช้' : 'Confirm account'}</Typography>
                  </MenuItem>
                )
              }
                <Divider />
                <MenuItem>
                  <TextField
                    select
                    label={lang == 'en' ? 'Choose Language': 'กรุณาเลือกภาษา'}
                    value={lang}
                    onChange={(e) => {setLang(e.target.value);}}
                    fullWidth={true}
                    variant="standard"
                  >
                    {LangList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </MenuItem>
              {login != null ? (lang == 'en' ?settingsEn:settingsTh).map((setting, i) => (
                <MenuItem key={setting} onClick={() => memberOptionAct(settingsEn[i])}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              )) : (
                <MenuItem onClick={() => {
                  setLogindia(true)
                  handleCloseUserMenu()
                }}>
                  <Typography textAlign="left"><ListItemText primary={lang == 'en' ? 'You are not Login' :'คุณยังไม่ได้เข้าสู่ระบบ'} secondary={lang == 'en' ? 'Please click here to login or register here' :'คลิกที่นี่เพื่อเข้าสู่ระบบหรือสมัครสมาชิก'} /></Typography>
                </MenuItem>
              )}
              <Divider />
              <MenuItem onClick={() => memberOptionAct('status')}>
                  <Typography textAlign="center">{lang == 'th' ? 'ตรวจสอบสถานะระบบ' : 'Check System Status'}</Typography>
                </MenuItem>
              <Divider />
                <MenuItem>
                  <FormControlLabel control={<Switch onClick={(e) => setDark(e.target.checked)} checked={dark} />} label={dark == true ? (<NightlightIcon />) : (<LightModeIcon />)} />
                </MenuItem>
            </Menu>
            
          </Box>
        </Toolbar>
      </AppBar>
      </Slide>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,backgroundColor: (dark ? '#02012b' : '') },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, color: (dark ? 'white' : '') }}>
      Menu
      </Typography>
      <Divider />
      <List>
        {(lang == 'en' ?navItemsEn:navItemsTh).map((item, i) => (
          <ListItem component={Link} onClick={() => changeroute('/' + navItemsLink[i])} key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center', color: (dark ? 'white' : '')}}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
        </Drawer>
      </Box>
      <Box className={width > 800 ? 'ml-4 mr-4' : 'container'} component="main" sx={{ paddingTop: login && !verify ? 10 : 5}}>
        <Toolbar />
        <div style={{marginBottom: footerHeight + 'px'}} className={load ? 'Loadstart' : 'Loadend'}>
          <BasicSwitch>
            <Route exact path="/">
              <Home login={login} setgreetall={(val) => setgreetall(val)} greetall={greetall} />
            </Route>
            <Route exact path="/artists">
              <Art />
            </Route>
            <Route exact path="/artist/:id">
              <ArtDetail footerref={ref} />
            </Route>
            <Route exact path="/live">
              <Live />
            </Route>
            <Route exact path="/news">
              <News login={login} />
            </Route>
            <Route exact path="/songlist">
              <TopChart />
            </Route>
            <Route exact path="/storyplay">
              <StoryPlay />
            </Route>
            <Route exact path="/weekplay">
              <WeekPlay login={login} />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/community">
              <CommunityList login={login} />
            </Route>
            <Route exact path="/status">
              <Status />
            </Route>
                <Route exact path="/communitypost/:id">
                  <CommunityDetail login={login} />
                </Route>
            {
              login == null ? (
                <Route exact path="/register">
                  <Regis login={login} />
                </Route>
              ): (
                <Route exact path="/membership">
                  <Acct login={login} setLogin={(v) => setLogin(v)} updateMem={() => updateMem()} />
                </Route>
            )
           }
           <Route path="*" render={() => {
            setLoad(true)
            setTimeout(() => history.push('/'), 500);
           }} />
          </BasicSwitch>
        </div>
      </Box>
      <br />
      <Slide in={!load} direction="up">
      <footer className={'fixed-bottom text-center' + (dark ? ' text-light bg-dark' :' text-dark bg-light')} ref={ref} style={dark == true ? {backgroundColor: 'gray'} : {}}>
        <ClickAwayListener onClickAway={() => setFootermore(false)}>
        <CardActionArea onClick={() => {
          if (footermore == false) {
            setFootermore(true)
          }
        }} className='pt-3'><h6>&copy; Copyright 2023 CPXDevStudio {footermore == false ? (<KeyboardArrowUpIcon />) : null}</h6></CardActionArea>
        </ClickAwayListener>
        <Collapse timeout={800} in={footermore}>
          <p>{lang == 'th' ? 'ข้อมูลและรูปภาพของศิลปินทั้งหมดเป็นกรรมสิทธิ์ของเจ้าของค่ายเพลงและตัวศิลปินเท่านั้น เว็บไซต์นี้มีวัตถุประสงค์เพื่อร่วมสนับสนุนศิลปินโดยไม่แสวงผลกำไร' : 'All artist information and images are the property of the record label owner and the artist themselves. This website is intended to support artists on a non-profit basis.'}</p>
        </Collapse>
      </footer>
      </Slide>



      <Dialog
        open={logindialog}
        onClose={() => setLogindia(false)}
        maxWidth='xl'
      >
        <DialogTitle>
          {lang == 'th' ? "เข้าสู่ระบบ T-POP Membership (Fan Space Membership)":"Login to T-POP Membership (Fan Space Membership)"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {lang == 'th' ? 'การเป็นสมาชิกกับพวกเราจะทำให้คุณได้รับสิทธิประโยชน์มากมาย และฟีเจอร์พิเศษเฉพาะสมาชิกเท่านั้นที่เข้าถึงได้' : 'For membership have received special privillage and exclusive feature of T-POP Fans eX Platform.'}
          </DialogContentText>
            <ButtonGroup variant="contained" fullWidth={width > 750 ? false : true} orientation={width > 750 ? "horizontal" : "vertical"} className='mt-5'>
              <Button variant='outlined'>{lang == 'th' ? 'เข้าสู่ระบบโดย' : 'Login as'}</Button>
              <Button onClick={() => loginAction(1)}><GoogleIcon/>&nbsp;Google Account</Button>
              <Button onClick={() => loginAction(2)}><MicrosoftIcon/>&nbsp;Microsoft Account</Button>
              <Button onClick={() => loginAction(3)}>Yahoo Account</Button>
            </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setLogindia(false);
            changeroute('/register');
          }}>{lang == 'th' ? 'สมัครสมาชิก' : 'Register'}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
