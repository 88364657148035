import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Provider } from "react-redux";
import {
  BrowserRouter
} from "react-router-dom";
import store from './redux/store'
import 'mapbox-gl/dist/mapbox-gl.css';

const theme = createTheme({
  typography: {
    fontFamily: 'misans',
  },
  palette: {
    secondary: {
      light: '#fff',
      main: '#1976d2',
      contrastText: '#fff',
    },
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);


serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
