import { CardHeader, Button, ButtonGroup, Card, Backdrop, Dialog, DialogTitle, DialogActions, DialogContent, Box, CircularProgress, FormControlLabel, Checkbox, Typography, List, ListItem, ListItemText, TextField, CardActionArea } from '@mui/material';
import React, { useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { useDebounceEffect } from '../utility/debounce'
import { canvasPreview } from '../utility/canvas'
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
  OAuthProvider,
  deleteUser
} from "firebase/auth";
import auth from "../fbindex";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import IconRoundedIcon from '@mui/icons-material/InfoRounded';

import {
  useHistory
} from "react-router-dom";

import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

let api;
let timerIntervalOTP;

const colorTemp = (score) => {
  if (score == 10) {
    return 'text-primary'
  } else if (score >= 8 && score < 10) {
    return 'text-info'
  } else if (score >= 5 && score < 8) {
    return 'text-success'
  } else if (score >= 3 && score <= 4) {
    return 'text-warning'
  } else {
    return 'text-danger'
  }
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" className={colorTemp((props.value / 100) * 10)} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.primary">
          {(props.value / 100) * 10}
        </Typography>
      </Box>
    </Box>
  );
}

const Acct = ({ dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage,
  login, setLogin, updateMem }) => {
  const [width, setRealwidth] = React.useState(window.innerWidth);
  // const [langselect, setLang] = React.useState('en');
  const history = useHistory();

  const [data, setData] = React.useState(null);
  const [loginLoad, setLoginLoad] = React.useState(false);
  const [bsk, setBsk] = React.useState(false);

  const [profile, setProfile] = React.useState('');
  const [temp, setTemp] = React.useState('');

  const [verload, setVerifyload] = useState(false)

  const [uploadimageload, setUp] = useState(false)
  const blobUrlRef = useRef('')
  const bskyform = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const previewCanvasRef = useRef(null)


  const [linkDialog, setLinkDia] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0);
  const [agree, setAgree] = React.useState(false);

  const meanbehav = (score) => {
    if (score == 10) {
      return lang == 'th' ? 'คุณยังไม่มีการโดนรายงานจากผู้ใช้งานใดๆ สุดยอด!' : 'You don\'t have any received reports this week. Awesome!'
    } else if (score >= 8 && score < 10) {
      return lang == 'th' ? 'คุณรักษาระดับคะแนนไว้ได้ดีเลยทีเดียว ทำต่อไปนะ!' : 'It\'s great scores! Please keep your best.'
    } else if (score >= 5 && score < 8) {
      return lang == 'th' ? 'คะแนนของคุณค่อนข้างต่ำกว่ามาตรฐานเล็กน้อย กรุณาปฏิบัติตามกฎของชุมชนเพื่อเป็นส่วนหนึ่งของคอมมูนิตี้นี้ให้ดีขึ้นได้' : 'It\'s not bad. But you need more following on our community rules to keep a better community.'
    } else if (score >= 3 && score <= 4) {
      return lang == 'th' ? 'เราตรวจพบว่าคุณไม่ปฏิบัติตามกฎของชุมชนออนไลน์ของเรา หากคุณยังคงปฏิบัติดังกล่าวอยู่ คุณอาจโดนจำกัดสิทธิ์การเข้าถึงถาวรได้' : 'We are detected that you break from our community rules. You maybe risk to permenent banned from our community if you still break our rules.'
    } else {
      return lang == 'th' ? 'คุณถูกจำกัดสิทธิ์การเข้าถึงขั้นสูงสุด โดยคุณสามารถดูบทความและคอมเมนท์ของคุณและผู้อื่นได้ แต่จะไม่สามารถมีส่วนร่วมในชุมชนออนไลน์นี้ได้ตลอดทั้งสัปดาห์ หากคุณยังคงไม่ปฏิบัติตามกฎของเรา คุณอาจโดนจำกัดสิทธิ์การเข้าถึงถาวรได้' : 'You have high limit access (View only articles and comments) because you do not follow our community rules throughout week. You maybe risk to permenent banned from our community if you still break our rules'
    }
  }
  const highlightcolor = (i, score) => {
    if (i == 1) {
      if (score >= 8 && score <= 10) {
        return 'text-light bg-info'
      } else if (score >= 5 && score < 8) {
        return ''
      } else if (score >= 3 && score <= 4) {
        return ''
      } else {
        return ''
      }
    }
    if (i == 2) {
      if (score >= 8 && score <= 10) {
        return ''
      } else if (score >= 5 && score < 8) {
        return 'text-light bg-info'
      } else if (score >= 3 && score <= 4) {
        return ''
      } else {
        return ''
      }
    }
    if (i == 3) {
      if (score >= 8 && score <= 10) {
        return ''
      } else if (score >= 5 && score < 8) {
        return ''
      } else if (score >= 3 && score <= 4) {
        return 'text-light bg-info'
      } else {
        return ''
      }
    }
    if (i == 4) {
      if (score >= 8 && score <= 10) {
        return ''
      } else if (score >= 5 && score < 8) {
        return ''
      } else if (score >= 3 && score <= 4) {
        return ''
      } else {
        return 'text-light bg-info'
      }
    }
  }

  function centerAspectCrop(
    mediaWidth,
    mediaHeight
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1 / 1,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  function onImageLoad(e) {
    if (1 / 1) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height))
    }
  }

  React.useEffect(() => {
    if (linkDialog == false) {
      setTimeout(() => {
        setActiveStep(0)
        setAgree(false)
      }, 1000);
    }
  }, [linkDialog])

  const UploadImageonAPI = (base) => {
    fetch('https://cpxdevweb.onrender.com/tpop/uploadMemImage/' + login.fromsystem.memId, {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        img: base
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == true) {
          setProfile(base)
          Swal.fire({
            title: 'Profile image has been changed',
            text: lang == 'th' ? "โปรไฟล์ถูกเปลี่ยนแล้ว" : "Profile change successfully.",
            icon: 'success',
            footer: 'Profile changed affected in T-POP Fans eX, BNK48 Fan Space and also CGM48 Fan Space platform'
          })
          setTimeout(() => {
            updateMem()
          }, 1000);
        } else {
          Swal.fire({
            title: 'Server error',
            text: lang == 'th' ? "พบข้อผิดพลาดเนื่องในระบบ กรุณาแจ้งเจ้าหน้าที่" : "Something went worng with service. Please contact us for find solution.",
            icon: 'error',
            footer: data.msg
          })
        }
        setUp(false)
      }).catch(() => {
        setUp(false)
        Swal.fire({
          title: 'Technical error',
          text: lang == 'th' ? "พบข้อผิดพลาดเนื่องในระบบ กรุณาแจ้งเจ้าหน้าที่" : "Something went worng with service. Please contact us for find solution.",
          icon: 'error'
        })
      });
  }

  const GetData = () => {
    setUp(true)
    setTemp('')
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        UploadImageonAPI(reader.result)
      }
    })
  }

  const UnlinktoBs = (m) => {
    setBsk(true)
    fetch('https://tpopcom.azurewebsites.net/api/authen/removelink', {
      method: 'put',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        memId: m
      })
    })
      .then(response => response.text())
      .then(data => {
        setBsk(false)
        if (data == "true") {
          Swal.fire({
            title: 'Unlink success',
            text: lang == 'th' ? 'คุณได้ยกเลิกการลิงก์กับ Bluesky แล้ว' : 'You are unlink from Bluesky Account successfully',
            icon: "success"
          })
        } else {
          Swal.fire({
            title: 'Something went wrong',
            text: lang == 'th' ? 'พบข้อผิดพลาด' : 'Please try again.',
            icon: "error"
          })
        }
      })
  }

  const getverify = () => {
    const obj = login.fromlogin;
    Swal.fire({
      title: "How to verify your account",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: lang == 'th' ? "เริ่มต้น" : "Get Started",
      cancelButtonText: lang == 'th' ? "ยกเลิก" : "Cancel",
      text:
        lang == 'th' ? 'หลังจากคุณกดปุ่ม "เริ่มต้น" ระบบจะส่งรหัส OTP ไปยังที่อยู่อีเมล์ที่คุณได้ลงทะเบียนไว้ หาก OTP ที่คุณกรอกถูกต้อง จะถือว่าการยืนยันตัวตนเสร็จสมบูรณ์'
          : 'After you click the "Get Started" button, you will receive an OTP sent to the email address you provided during registration. Please copy that OTP code into next dialog to confirm it. If OTP is correct, your account will be verified scuccessfully.',
    }).then((result) => {
      if (result.isConfirmed) {
        setVerifyload(true)
        fetch('https://cpxdevweb.onrender.com/tpop/reverify?i=' + obj.uid, {
          method: 'post'
        })
          .then(response => response.json())
          .then(data => {
            setVerifyload(false)
            if (data.status == true) {
              Swal.fire({
                title: lang == 'th' ? 'กรุณากรอก OTP เพื่อยืนยันบัญชีผู้ใช้ ให้ตรวจสอบรหัส OTP จากที่อยู่อีเมล์ของคุณ' : 'Please confirm to verify Fan Space Membership Account by enter OTP. Please check your email inbox.',
                html: lang == 'th' ? ('กรุณากรอก OTP ภายในเวลา <strong></strong> วินาที รหัสอ้างอิง OTP คือ <b>' + data.otp + '</b>') : ('Please enter OTP in <strong></strong> second(s). OTP RefID is <b>' + data.otp + '</b>'),
                input: 'text',
                inputAttributes: {
                  autocapitalize: 'off',
                  placeholder: lang == 'th' ? "กรุณากรอก OTP ที่คุณได้รับ" : "Enter OTP which we sent to your current email",
                  maxlength: 6,
                  required: true
                },
                allowOutsideClick: false,
                showDenyButton: true,
                confirmButtonText: lang == 'th' ? 'ยืนยัน' : 'Confirm',
                denyButtonText: lang == 'th' ? 'ยกเลิก' : `Cancel`,
                timer: 180000,
                didOpen: () => {
                  Swal.showLoading();
                  api = setTimeout(() => {
                    Swal.hideLoading();
                    clearTimeout(api);
                  }, 3000)
                  timerIntervalOTP = setInterval(() => {
                    Swal.getHtmlContainer().querySelector('strong')
                      .textContent = (Swal.getTimerLeft() / 1000)
                        .toFixed(0)
                  }, 100)
                },
                preConfirm: function (val) {
                  if (val.length === 0 || val.length < 6) {
                    Swal.showValidationMessage(lang == 'th' ? `กรุณากรอก OTP เพื่อยืนยันการเปลี่ยนแปลง` : `Please enter OTP to confirm changing`)
                  }
                  return { otpget: val };
                },
                didClose: () => {
                  if (Swal.getTimerLeft() < 100) {
                    Swal.fire({
                      title: 'OTP is expired',
                      text: lang == 'th' ? 'เซสชั่นการยืนยัน OTP หมดอายุ กรุณาลองใหม่อีกครั้ง' : 'Please try again.',
                      icon: "error"
                    })
                  }
                },
                willClose: () => {
                  setVerifyload(false)
                  clearTimeout(api);
                  clearInterval(timerIntervalOTP);
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  setVerifyload(true)
                  fetch("https://cpxdevweb.onrender.com/tpop/setverify?i=" + obj.uid + "&otp=" + result.value.otpget + "&refid=" + data.otp, {
                    method: 'put'
                  })
                    .then(x => x.json())
                    .then(y => {
                      if (y.verified == true) {
                        Swal.fire({
                          title: 'Verify success',
                          text: lang == 'th' ? 'ยืนยันบัญชีผู้ใช้เรียบร้อยแล้ว' : "Thank you for verify your account",
                          icon: "success"
                        }).then(() => {
                          updateMem();
                          fetchx();
                        })
                      } else {
                        setVerifyload(false)
                        Swal.fire({
                          title: 'Something went wrong',
                          text: y.message,
                          icon: "error"
                        })
                      }
                    }).catch(() => {

                    });
                }
              })
            } else {
              Swal.fire({
                title: "Error while send OTP, please try again",
                icon: 'error',
                text: 'Please take for a while or contact us.',
              })
            }
          }).catch(() => {
            setData(null)
          })
      }
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        )
      }
    },
    100,
    [completedCrop, 1, 0],
  )

  const fetchx = () => {
    fetch('https://cpxdevweb.onrender.com/tpop/getlogin?i=' + login.fromsystem.memId, {
      method: 'post'
    })
      .then((response) => response.json())
      .then((d) => {
        setVerifyload(false)
        setLoad(false)
        setLoginLoad(false)
        if (d.status == 0) {
          setData(d.response)
          setProfile(d.response.img)
        }
      });
  }

  React.useEffect(() => {
    function handleWindowResize() {
      setRealwidth(window.innerWidth);
    }
    sessionStorage.removeItem('artlistprevious')
    if (lang == 'th') {
      setPage('ตั้งค่าบัญชี')
    } else {
      setPage('Account Setting')
    }
    window.addEventListener('resize', handleWindowResize);
    setLoad(true)
    fetchx();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    if (login == null) {
      setLoad(true)
      setTimeout(() => history.push('/'), 500);
    }
  }, [login]);

  const loginAction = (id, action) => {
    if (data.verified == false) {
      Swal.fire({
        title: 'You cannot link more account',
        text: lang == 'th' ? "กรุณายืนยันบัญชีผู้ใช้ก่อนเพิ่มบัญชีผู้ใช้สำหรับเข้าสู่ระบบ" : "Please confirm account before add more linked account",
        icon: 'warning'
      })
      return;
    }
    let provider = null
    let actionkit = ''
    setLoginLoad(true)
    switch (action) {
      case 1:
        actionkit = 'google_id'
        provider = new GoogleAuthProvider();
        break;
      case 2:
        actionkit = 'twitter_id'
        provider = new OAuthProvider("microsoft.com");;
        break;
      case 3:
        actionkit = 'yahoo_id'
        provider = new OAuthProvider("yahoo.com");
        break;
      default:
        return;
    }
    signInWithPopup(auth, provider)
      .then((result) => {
        fetch('https://cpxdevweb.onrender.com/tpop/updateLinkAcoount?i=' + id + '&servicename=' + actionkit + '&id=' + result.user.uid, {
          method: 'put'
        })
          .then(response => response.json())
          .then(data => {
            setLoginLoad(false)
            if (data.status == 0) {
              let temp = login
              let param = data.response
              delete param.articleAll
              delete param.articleThisYear
              delete param.commentThisYear
              delete param.commentAll
              temp.fromsystem = param
              setLogin(temp)
              setData(data.response)
              localStorage.setItem("tpoploged", JSON.stringify(temp));
            } else if (data.status == 3) {
              // deleteUser(result.user)
              Swal.fire({
                title: 'Membership System is under maintenance',
                text: lang == 'th' ? "อยู่ระหว่างการปรับปรุงระบบ คุณยังไม่สามารถผูกบัญชีได้ในขณะนี้ ขออภัยในความไม่สะดวก" : "Membership is under maintenance. You cannot login right now. Sorry for inconvenience",
                icon: 'error'
              })
            } else {
              // deleteUser(result.user)
              Swal.fire({
                title: 'System error',
                text: lang == 'th' ? "พบข้อผิดพลาดเนื่องในระบบ กรุณาแจ้งเจ้าหน้าที่" : "Something went worng with service. Please contact us for find solution.",
                icon: 'error'
              })
            }
          });
      })
      .catch((error) => {
        setLoginLoad(false)
        Swal.fire({
          title: 'Login error or canceled by user',
          text: lang == 'th' ? "การผูกบัญชีล้มเหลว" : "Link Account fail",
          icon: 'warning'
        })
      });
  }

  const bsAccess = (m) => {
    setLoginLoad(true)
    fetch('https://tpopcom.azurewebsites.net/api/authen/checkaccount?id=' + m, {
      method: 'get'
    })
      .then((response) => response.text())
      .then((data) => {
        setLoginLoad(false)
        if (data == "2") {
          Swal.fire({
            title: 'Linked success',
            text: lang == 'th' ? "บัญชีคุณได้ลิงก์กับบัญชี BlueSky แล้ว คุณสามารถใช้งานฟีเจอร์คอมมูนิตี้ได้ไม่มีข้อจำกัดแล้ว" : "You are already linked to BlueSky Account. You can fully access T-POP Community features.",
            icon: 'success',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: lang == 'th' ? "ไปหน้าคอมมูนิตี้" : 'Navigate to T-POP Community',
            denyButtonText: lang == 'th' ? `ยกเลิกการลิงก์กับ Bluesky` : 'Unlink Bluesky Account',
            cancelButtonText: lang == 'th' ? 'ปิด' : 'Close'
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/community')
            } else if (result.isDenied) {
              Swal.fire({
                title: 'Confirm to unlinked Bluesky Account',
                text: lang == 'th' ? "หลังจากที่คุณยกเลิกการลิงก์แล้ว คุณจะไม่สามารถสร้างโพสต์ คอมเม้นต์หรือกดชื่นชอบโพสต์หรือคอมเม้นต์ได้ แต่คุณยังคงสามารถดูโพสต์และคอมเม้นต์ได้ตามปกติ ข้อความที่คุณเคยโพสต์และคอมเม้นต์จะไม่ได้ถูกลบไปด้วย" : "After unlinked BlueSky Account, You can view only posts and comments. But you cannot create post, get comment and react your like to posts/comments. All previous your posts and comments not be deleted.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: lang == 'th' ? "ยืนยัน" : 'Confirm',
                cancelButtonText: lang == 'th' ? 'ปิด' : 'Close'
              }).then((result) => {
                if (result.isConfirmed) {
                  UnlinktoBs(m)
                }
              });
            }
          });
        } else if (data == "1") {
          Swal.fire({
            title: 'Your account is ready to link',
            text: lang == 'th' ? "คุณยังไม่ได้ลิงก์กับบัญชี Bluesky เพื่อเปิดใช้งานฟีเจอร์คอมมูนิตี้ คลิก 'ผูกบัญชี' เพื่อเชื่อมบัญชีกับ Bluesky Account" : "You don't link to Bluesky account to enable full access of T-POP Community feature yet. Click 'Link to Bluesky Account' to get started",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: lang == 'th' ? "ผูกบัญชี" : 'Link to Bluesky Account',
            cancelButtonText: lang == 'th' ? 'ปิด' : 'Close'
          }).then((result) => {
            if (result.isConfirmed) {
              setLinkDia(true)
            }
          });
        } else {
          Swal.fire({
            title: 'Membership Account is unverifiy',
            text: lang == 'th' ? "กรุณายืนยัน Fan Space Membership เพื่อเปิดใช้งานฟีเจอร์นี้" : "Please verify your membership account.",
            icon: 'warning'
          })
        }
      });
  }


  const UploadImage = () => {
    if (data.verified == false) {
      Swal.fire({
        title: 'You cannot change profile image',
        text: lang == 'th' ? "กรุณายืนยันบัญชีผู้ใช้ก่อนเพิ่มหรือเปลี่ยนโปรไฟล์รูปภาพ" : "Please confirm account before upload profile image",
        icon: 'warning'
      })
      return;
    }
    Swal.fire({
      title: 'All changed profile image will be affect with T-POP Megaverse Membership and Fan Space Platform (BNK48 and CGM48 Fan Space Platform)',
      text: lang == 'th' ? "รูปโปรไฟล์จะมีผลทั้งบนแพลตฟอร์ม T-POP Megaverse รวมทั้ง BNK48 Fan Space และ CGM48 Fan Space ด้วย" : "This profile will be shown both T-POP Megavese and also BNK48 Fan Space and CGM48 Fan Space Platform too.",
      icon: 'info'
    }).then(() => {
      var input = document.createElement('input');
      input.type = 'file';
      input.click();
      input.addEventListener('change', function () {
        setUp(true)
        if (!input.files) { // This is VERY unlikely, browser support is near-universal
          console.error("This browser doesn't seem to support the `files` property of file inputs.");
        } else {
          var file = input.files[0];
          if (!file.type.includes('jpeg') && !file.type.includes('png')) {
            setUp(false)
            Swal.fire({
              title: 'File type is unsupport',
              text: lang == 'th' ? "คุณสามารถอัปโหลดเป็นไฟล์รูปภาพได้เท่านั้น" : "Please choose only image file.",
              icon: 'warning'
            })
            input.value = null
          } else if (file.size > 3145728) {
            setUp(false)
            Swal.fire({
              title: 'File size exceeded.',
              text: lang == 'th' ? "ไฟล์รูปภาพมีขนาดใหญ่เกินไป" : "File is too large.",
              icon: 'warning'
            })
            input.value = null
          } else {
            if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                setUp(false)
                setTemp(e.target.result)
              }

              reader.readAsDataURL(input.files[0]); // convert to base64 string
            }

          }
        }
      }, false);
    })
  }

  const StartLinkBs = (e) => {
    e.preventDefault()
    if (bskyform.current["user"].value == '' || bskyform.current["pass"].value == '') {
      Swal.fire({
        title: 'Username or Password is blank',
        text: lang == 'th' ? "กรุณากรอกข้อมูลก่อนดำเนินการต่อ" : "Please enter required fields to continue.",
        icon: 'warning'
      })
      return;
    }
    if (bskyform.current["user"].value.includes('.bsky.social')) {
      Swal.fire({
        title: 'Enter only Bluesky Social Account name',
        text: lang == 'th' ? "กรุณากรอกเฉพาะชื่อผู้ใช้ Bluesky Social อย่างเดียว (ไม่ต้องมี .bsky.social ต่อท้าย)" : "Enter only username of Bluesky Social (No '.bsky.social' need)",
        icon: 'warning'
      })
      return;
    }
    if (bskyform.current["pass"].value.length < 8) {
      Swal.fire({
        title: "Password is too short",
        text: lang == 'th' ? "รหัสผ่านคุณสั้นหรืออาจคาดเดาง่ายเกินไป ซึ่งอาจส่งผลต่อการเข้าถึงบัญชีผู้ใช้ Bluesky Social ของคุณ" : "Your password  is too short or maybe easy to guess. It maybe affected to Bluesky Social Account",
        icon: 'info',
        footer: lang == 'th' ? "จำนวนและความยากของการคาดเดารหัสผ่านไม่ส่งผลกระทบต่อการเข้าถึงฟีเจอร์ใน T-POP Fans eX แต่อย่างใด" : "The number and difficulty of guessing passwords do not affect toaccess the features in T-POP Fans eX in any way.",
      })
    }

    setBsk(true)
    fetch('https://tpopcom.azurewebsites.net/api/authen/createlink', {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        memId: data.memId,
        bsUser: bskyform.current["user"].value,
        bsPass: btoa(bskyform.current["pass"].value)
      })
    })
      .then(response => response.text())
      .then(data => {
        setBsk(false)
        if (data == "true") {
          setLinkDia(false)
          Swal.fire({
            title: 'Link success',
            text: lang == 'th' ? 'คุณได้ลิงก์กับ Bluesky แล้ว คุณสามารถเข้าใช้งาน T-POP Community ได้เต็มรูปแบบ' : 'You are link from Bluesky Account successfully',
            icon: "success",
            showCancelButton: true,
            confirmButtonText: lang == 'th' ? "ไปยังหน้าคอมมูนิตี้" : 'Go to T-POP Community',
            cancelButtonText: lang == 'th' ? 'ปิด' : 'Close'
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/community')
            }
          });
        } else {
          Swal.fire({
            title: 'Something went wrong',
            text: lang == 'th' ? 'พบข้อผิดพลาด' : 'Please try again.',
            icon: "error"
          })
        }
      })
  }


  if (data == null) {
    return (
      <Backdrop
        sx={{ zIndex: -1, position: 'fixed', backgroundColor: (dark ? "#02012b" : "#fff") }}
        open={true}
      >
        {lang == 'th' ? 'อยู่ระหว่างการปรับปรุงระบบสมาชิก คุณสามารถกลับมาอีกครั้งในภายหลัง ขออภัยในความไม่สะดวก' : 'The membership service is currently under maintenance, you can come back later. Sorry for inconvenience'}
      </Backdrop>
    )
  }
  return (<>
    <Backdrop
      sx={{ zIndex: 2000, position: 'fixed' }}
      open={uploadimageload}
      className={dark ? 'text-light' : ''}
    >
      <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
      {lang == 'th' ? 'กำลังอัปโหลดรูป กรุณารอสักครู่' : 'Uploading your profile image, please wait'}
    </Backdrop>
    <Dialog open={temp != '' ? true : false} maxWidth='xl'>
      <DialogTitle>{lang == 'th' ? 'ครอบรูปภาพที่ต้องการตั้งเป็นโปรไฟล์' : 'Crop you selected image to set profile'}</DialogTitle>
      <DialogContent>
        {
          width > 1100 || (width <= 1100 && window.innerHeight > window.innerWidth) ? (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1 / 1}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={temp}
                width='100%'
                height={width > 700 ? '600px' : '100%'}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          ) : (
            <div className='text-center'>
              {lang == 'th' ? 'กรุณาหมุนโทรศัพท์มือถือของคุณเป็นแนวตั้ง' : 'Please rotate your phone screen to portrait'}
            </div>
          )
        }
        <canvas
          ref={previewCanvasRef}
          style={{
            height: 0,
            width: 0,
            visibility: 'hidden'
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => GetData()} autoFocus disabled={width > 1100 || (width <= 1100 && window.innerHeight > window.innerWidth) ? false : true}>
          {lang == 'th' ? 'อัปโหลด' : 'Upload'}
        </Button>
        <Button onClick={() => setTemp('')}>
          {lang == 'th' ? 'ยกเลิก' : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
    <CardHeader className={dark ? 'text-light' : ''} title={lang == 'th' ? 'ข้อมูลสมาชิกและการตั้งค่า' : 'Membership Account information and setings'} />
    <div className={'container mt-4' + (dark ? ' text-light' : '')}>
      <div className='row'>

        {
          profile != '' ? (
            <div className='col-md-3 m-3 img-zoom imgcircle' data-aos="zoom-out">
              <img className='imgcircle' src={profile} width="100%" />
            </div>
          ) : (
            <div className='col-md-3 m-5 point bg-light' data-aos="zoom-out">
              <Backdrop
                sx={{ zIndex: 500, position: 'fixed' }}
                open={true}
                className='text-light'
              >
                {lang == 'th' ? 'คลิกที่นี่เพื่ออัปโหลดโปรไฟล์' : 'Click here to upload profile image'}
              </Backdrop>
            </div>
          )
        }
        <div className='col-md'>
          <div className='row m-3'>
            <div className='col-lg-7'>
              {lang == 'th' ? 'ชื่อผู้ใช้' : 'Username'}
            </div>
            <div className='col-lg-5 text-right'>
              {data.memUser}
            </div>
          </div>
          <div className='row m-3'>
            <div className='col-lg-7'>
              {lang == 'th' ? 'ที่อยู่อีเมล์ที่ติดต่อ' : 'Email'}
            </div>
            <div className='col-lg-5 text-right'>
              {data.memEmail}
            </div>
          </div>
          <div className='row m-3'>
            <div className='col-lg-7'>
              {lang == 'th' ? 'เวลาที่ลงทะเบียน' : 'Registered date'}
            </div>
            <div className='col-lg-5 text-right'>
              {moment.utc(data.regis, 'M/D/YYYY H:mm:ss A').local().lang(lang).format("DD MMMM YYYY HH:mm:ss")}
            </div>
          </div>
          <div className='row m-3'>
            <div className='col-lg-7'>
              {lang == 'th' ? 'ภูมิภาคของ T-POP System' : 'T-POP Region system'}
            </div>
            <div className='col-lg-5 text-right'>
              {data.tpopRegion != '' ? data.tpopRegion : 'Global'}
            </div>
          </div>
          <CardActionArea className='p-0' onClick={() => setLinkDia(true)}>
            <div className='row m-3'>
              <div className='col-lg-7'>
                {lang == 'th' ? 'คะแนนความประพฤติใน T-POP Community' : 'Community Behavior score'}&nbsp;<IconRoundedIcon />
              </div>
              <div className='col-lg-5 text-right'>
                {data.point}{lang == 'th' ? ' คะแนน' : ' Point(s)'}
              </div>
            </div>
          </CardActionArea>
          {/* <div className='row m-3'>
            <div className='col-lg-7'>
            {lang == 'th' ? 'จำนวนบทความที่คุณโพสต์ในปีนี้' : 'All your uploaded articles in this year'}
            </div>
            <div className='col-lg-5 text-right'>
                {data.articleThisYear}{lang == 'th' ? ' บทความ' : ' article(s)'}
            </div>
        </div>
        <div className='row m-3'>
        <div className='col-lg-7'>
            {lang == 'th' ? 'จำนวนความคิดเห็นของคุณในปีนี้' : 'All your comments in this year'}
            </div>
            <div className='col-lg-5 text-right'>
                {data.commentThisYear}{lang == 'th' ? ' ความคิดเห็น' : ' comment(s)'}
            </div>
        </div> */}
        </div>
      </div>
      {
        !data.verified && (
          <div className='row col-12 justify-content-center m-3'>
            <Button variant='outlined' onClick={() => getverify()}>{lang == 'th' ? 'ยืนยันบัญชีผู้ใช้ที่นี่' : 'Click here to verify Fan Space Membership account'}</Button>
          </div>
        )
      }
      <Card className='mt-5'>
        <div className='row m-3'>
          <div className='col-lg-2 pt-2'>
            {lang == 'th' ? 'บัญชีที่ใช้เข้าสู่ระบบ' : 'Linked Account'}
          </div>
          <div className='col-lg-10 text-right'>
            <ButtonGroup variant="contained" fullWidth={width > 750 ? false : true} orientation={width > 750 ? "horizontal" : "vertical"} className=''>
              <Button onClick={() => loginAction(data.memId, 1)} disabled={data.googleId != '' ? true : false}><GoogleIcon />&nbsp;Google Account</Button>
              <Button onClick={() => loginAction(data.memId, 2)} disabled={data.msId != '' ? true : false}><MicrosoftIcon />&nbsp;Microsoft Account</Button>
              <Button onClick={() => loginAction(data.memId, 3)} disabled={data.yahooId != '' ? true : false}>Yahoo Account</Button>
            </ButtonGroup>
          </div>
        </div>
        {
          data.verified && (
            <div className='row m-3 justify-content-center point' onClick={() => UploadImage()}>
              {lang == 'th' ? 'คุณสามารถคลิกที่นี่เพื่อเปลี่ยนรูปโปรไฟล์ได้แล้ว' : 'You can click here to change new profile image'}
            </div>
          )
        }
      </Card>

      <Backdrop
        sx={{ zIndex: 2000, position: 'fixed' }}
        open={loginLoad}
        className={dark ? 'text-light' : ''}
      >
        <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
        {lang == 'th' ? 'กำลังเชื่อมบัญชี กรุณารอสักครู่' : 'Linking account, please wait'}
      </Backdrop>
      <Backdrop
        sx={{ zIndex: 2000, position: 'fixed' }}
        open={verload}
        className={dark ? 'text-light' : ''}
      >
        <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
        {lang == 'th' ? 'กำลังยืนยันบัญชี กรุณารอสักครู่' : 'Verifying account, please wait'}
      </Backdrop>
      <Backdrop
        sx={{ zIndex: 2000, position: 'fixed' }}
        open={bsk}
        className={dark ? 'text-light' : ''}
      >
        <img src='https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg' width='60px' />
        {lang == 'th' ? 'กำลังอัปเดตข้อมูล' : 'Updating account, please wait'}
      </Backdrop>
    </div>
    <Dialog open={linkDialog} maxWidth='xl'>
      <DialogTitle component='h4'>{lang == 'th' ? 'คะแนนความประพฤติของ T-POP Community คืออะไร' : 'What is Community Behavior score?'}</DialogTitle>
      <DialogContent>
        <h6 style={{ fontSize: 18 }}>{lang == 'th' ? 'คะแนนของคุณในสัปดาห์นี้' : 'Your weekly scores'}</h6>
        <div className='row'>
          <div className='col-md-auto'>
            <CircularProgressWithLabel value={(data.point / 10) * 100} />
          </div>
          <div className='col-md mt-2'>
            <p>{meanbehav(data.point)}</p>
          </div>
        </div>
        <Typography className='mt-3'>{lang == 'th' ? 'คะแนนความประพฤติจะเป็นตัวบ่งชี้พฤติกรรมในการใช้งาน Community ได้ถูกต้องตามกฎหรือไม่ และไม่ขัดต่อชุมชนออนไลน์ของเรา ซึ่งแต่ละคะแนนจะมีผลต่อการใช้งาน Community ของคุณในตลอดทั้งสัปดาห์นั้น ทั้งนี้คุณสามารถตรวจสอบที่ตารางด้านล่างเพื่อเทียบผลคะแนนปัจจุบันกับบริการของ Community ที่คุณสามารถใช้งาน' :
          'Behavior scores will be an indicator of behavior in using the Community in accordance with the rules or not. and does not offend our online community. Each score will affect your use of the Community throughout the week. You can check the table below to compare your current scores with the Community features you can use.'}</Typography>

        <TableContainer className='mt-3' component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>{lang == 'th' ? 'ฟีเจอร์' : 'Features'}</TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}>{lang == 'th' ? '8-10 คะแนน' : '8 to 10 Points'}</TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}>{lang == 'th' ? '5-7 คะแนน' : '5 to 7 Points'}</TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}>{lang == 'th' ? '3-4 คะแนน' : '3 to 4 Points'}</TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}>{lang == 'th' ? 'น้อยกว่า 2 คะแนน' : 'Less than 2 Points'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {lang == 'th' ? 'ดูบทความหรือดูคอมเมนท์ได้' : 'View all articles and comments'}
                </TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}><CheckCircleOutlineIcon /></TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {lang == 'th' ? 'โพสต์บทความหรือรูปภาพได้' : 'Post article or with images'}
                </TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}><DoNotDisturbOnIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}><DoNotDisturbOnIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}><DoNotDisturbOnIcon /></TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {lang == 'th' ? 'แสดงความคิดเห็นบนบทความได้' : 'Comment on articles'}
                </TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}><DoNotDisturbOnIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}><DoNotDisturbOnIcon /></TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {lang == 'th' ? 'กดชื่นชอบบทความหรือชื่นชอบคอมเมนท์ได้' : 'Like articles and/or comments'}
                </TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}><DoNotDisturbOnIcon /></TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {lang == 'th' ? 'รายงานบทความหรือคอมเมนท์ที่เข้าข่ายผิดกฎชุมชน' : 'Report break of rules articles and/or comments'}
                </TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}><DoNotDisturbOnIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}><DoNotDisturbOnIcon /></TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {lang == 'th' ? 'ลบบทความหรือคอมเมนท์' : 'Delete your articles and/or comments'}
                </TableCell>
                <TableCell align="right" className={highlightcolor(1, data.point)}><CheckCircleOutlineIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(2, data.point)}><DoNotDisturbOnIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(3, data.point)}><DoNotDisturbOnIcon /></TableCell>
                <TableCell align="right" className={highlightcolor(4, data.point)}><DoNotDisturbOnIcon /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setLinkDia(false)}>{lang == 'th' ? 'ปิด' : 'Close'}</Button>
      </DialogActions>
    </Dialog>
  </>);
}

const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Acct);