import React from 'react'
import pagedetail from '../menulist/Artists.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, CardActionArea, CardContent, Pagination , TextField, MenuItem, FormControlLabel, Switch, Avatar, ListItemText, Grid, ListItem } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';

import {
  useParams,
  useHistory
} from "react-router-dom";
import usePagination from "../pagination";

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Art = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage
}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    const [rootArr, setRootArr] = React.useState([]);
    const [sampleArr, setSampleArr] = React.useState([]);

    const [search, setSearch] = React.useState('');
    const [type, setType] = React.useState('.');

    const [ListMode, setListMode] = React.useState(localStorage.getItem('list') != null ? true : false);

    const [pageset, setPagin] = React.useState(1);
    const PER_PAGE = 8;

    let count = Math.ceil(sampleArr.length / PER_PAGE);
    let _DATA = usePagination(sampleArr, PER_PAGE);

    const handleChange = (e, p) => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setPagin(p);
      _DATA.jump(p);
    };

    const History = useHistory();
  
    const FetchData = () => {
      setLoad(true)
      fetch('https://cpxdevweb.onrender.com/tpop/artistlist?lang='+lang, {
        method: 'post'
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setRootArr(data.sort((a, b) => (a.artName[lang] > b.artName[lang]) ? 1 : ((a.artName[lang] < b.artName[lang]) ? -1 : 0)))
            setSampleArr(data.sort((a, b) => (a.artName[lang] > b.artName[lang]) ? 1 : ((a.artName[lang] < b.artName[lang]) ? -1 : 0)))
          }
          setLoad(false)
        });
    }
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (lang == 'th') {
        setPage('รายชื่อสิลปิน')
      } else {
        setPage('Artists')
      }
      window.addEventListener('resize', handleWindowResize);
      FetchData()
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);
    
    React.useEffect(() => {
      let temp = sampleArr;
      setSampleArr([])
      setSampleArr(temp.sort((a, b) => (a.artName[lang] > b.artName[lang]) ? 1 : ((a.artName[lang] < b.artName[lang]) ? -1 : 0)))
    }, [lang]);

    const changep = (artid) => {
      setLoad(true)
      setTimeout(() => History.push('/artist/' + artid), 600)
    }

    const artgroup = [
      {
        value: '.',
        label: (lang == 'th' ? 'ทั้งหมด' : 'All'),
      },
      {
        value: 'single',
        label: (lang == 'th' ? 'ศิลปินเดี่ยว' : 'Single Artist'),
      },
      {
        value: 'duo',
        label: (lang == 'th' ? 'ศิลปินคู่' : 'Duo Artist'),
      },
      {
        value: 'group',
        label: (lang == 'th' ? 'ศิลปินกลุ่ม' : 'Group Artist'),
      },
    ];

    const SearchHandle = (val)=>{
      if (pageset > 1) {
        setPagin(1)
        _DATA.jump(1);
       }
        setSearch(val.toLowerCase())
        let temp = rootArr
        if (val == '') {
          if (type != '.') {
            temp = temp.filter(x => x.artType.en == type)
          }
          setSampleArr(temp)
        } else {
          if (type != '.') {
            temp = temp.filter(x => x.artType.en == type)
            temp =temp.filter(x => x.artName.en.toLowerCase().includes(val) || x.artName.th.includes(val))
          } else {
            temp = temp.filter(x => x.artName.en.toLowerCase().includes(val) || x.artName.th.includes(val))
          }
          console.log(temp)
          setSampleArr(temp)
        }
    }


    const FilterHandle = (val)=>{
     if (pageset > 1) {
      setPagin(1)
      _DATA.jump(1);
     }
      setType(val.toLowerCase())
      let temp = rootArr
      if (search == '') {
        if (val != '.') {
          temp = temp.filter(x => x.artType.en == val)
        }
      } else {
        if (val != '.') {
          temp = temp.filter(x => x.artType.en == val)
        }
        temp = temp.filter(x => x.artName.en.toLowerCase().includes(search) || x.artName.th.includes(search))
      }
      setSampleArr(temp)
      count = Math.ceil(temp.length / PER_PAGE);
      _DATA = usePagination(temp, PER_PAGE);
  }
    

  React.useEffect(() => {
    const current = document.documentElement.scrollTop
    window.scrollTo(0, document.documentElement.scrollHeight);
    window.scrollTo(0, 0);
    window.scrollTo(0, current);
  }, [dark])

  React.useEffect(() => {
    if (ListMode) {
      localStorage.setItem('list', '')
    } else {
      localStorage.removeItem('list')
    }
  }, [ListMode])

     

    if (load) return null
    return ( 
        <div>
          <CardHeader title={(<h3 className={dark ? 'text-light' : ''}>{pagedetail[lang].title}</h3>)}
          action={width >700 ?(
            <FormControlLabel control={<Switch className={dark ? 'changemodedark' : 'changemode'} onClick={(e) => setListMode(e.target.checked)} checked={ListMode} />} label={ListMode == true ? (<div className={dark ? 'text-light' : ''}><ListIcon /> {lang == 'th' ? 'แสดงเป็นรายการ' : 'Show as List'}</div>) : (<div className={dark ? 'text-light' : ''}><GridViewIcon /> {lang == 'th' ? 'แสดงในรูปตาราง' : 'Show as Grid'}</div>)} />
          ) : null}
          />
          {width <=700 ? ( <FormControlLabel className='pl-3' control={<Switch className={dark ? 'changemodedark' : 'changemode'} onClick={(e) => setListMode(e.target.checked)} checked={ListMode} />} label={ListMode == true ? (<div className={dark ? 'text-light' : ''}><ListIcon /> {lang == 'th' ? 'แสดงเป็นรายการ' : 'Show as List'}</div>) : (<div className={dark ? 'text-light' : ''}><GridViewIcon /> {lang == 'th' ? 'แสดงในรูปตาราง' : 'Show as Grid'}</div>)} />) : null}
          <Card>
            <CardContent className='row ml-2 mr-2'>
            <TextField
                label={lang == 'th' ? 'ค้นหาศิลปิน' : 'Search artist'}
                value={search}
                className='col-md-4'
                fullWidth={true}
                variant="standard"
                onChange={(e)=> SearchHandle(e.target.value)}
              />
              <div className='col-md-1'></div>
               <TextField
                select
                value={type}
                label={lang == 'th' ? 'เลือกประเภทศิลปิน' : 'Select artist type'}
                fullWidth={true}
                onChange={(e)=> FilterHandle(e.target.value)}
                variant="standard"
                className={'col-md-3' + (width < 600 ? ' mt-2' : '')}
              >
          {artgroup.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
            </CardContent>
          </Card>
          {ListMode ?(

          <div className='mt-4'>
                <Grid className='justify-content-center text-center container'>
                <TableContainer component={Paper} sx={{backgroundColor: (dark ? '#02012b' : '')}}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={dark ? 'text-light' : ''}><h5>{lang == 'th' ? 'รูปศิลปิน' : 'Artist Image'}</h5></TableCell>
                        <TableCell className={dark ? 'text-light' : ''}><h5>{lang == 'th' ? 'ชื่อศิลปิน' : 'Artist Name'}</h5></TableCell>
                        <TableCell className={dark ? 'text-light' : ''}><h5>{lang == 'th' ? 'ประเภทศิลปิน' : 'Artist Group'}</h5></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sampleArr.map((item, i) => (
                        <TableRow
                          className='point'
                          onClick={() =>  changep(item.artId)}
                          key={item.artId}
                          data-aos="fade-right"
                          hover selected
                        >
                          <TableCell className='col-3'>
                          <div className='img-zoom imgcircle'>
                              <CardMedia className='imgcircle' src={item.artImg} sx={{width: width <= 700 ? 100 : '100%'}} component='img' />
                      </div>
                          </TableCell>
                          <TableCell className={dark ? 'text-light' : ''}><h6>{item.artName[lang]}</h6></TableCell>
                          <TableCell className={dark ? 'text-light' : ''}>{lang == 'th' && 'ศิลปิน'}{capitalizeFirstLetter(item.artType[lang])}{lang == 'en' && ' Artist'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                  </Grid>

                </div>
          ) : (
          <div className='text-center mt-4'>
          {
            sampleArr.length > 8 && (
              <div className='col-md-12 d-flex justify-content-center mb-3'>
                <Pagination
                count={count}
                  size="large"
                  page={pageset}
                  color={dark ? "secondary" :"primary"}
                  className='draktextpaging'
                  onChange={handleChange}
                />
              </div>
            )
          }
          <Grid container className='justify-content-center' spacing={2}>
          {_DATA.currentData().map((item, i) => (
            <Grid item lg={3} md={4}>
                <Card data-aos="zoom-in-up" key={item.artId} className={'mt-2 text-center'} sx={{backgroundColor: (dark ? '#02012b' : '')}}>
                  <CardContent>
                    <CardActionArea onClick={() =>  changep(item.artId)}>
                      <div className='img-zoom imgcircle'>
                        <CardMedia className='mb-2 imgcircle' src={item.artImg} component='img' />
                      </div>
                        <Typography variant='h5' className={dark ? 'text-light' : ''}>
                          {item.artName[lang]}
                        </Typography>
                        <Typography variant='subtitle2' className={dark ? 'text-light' : ''}>
                        {lang == 'th' && 'ศิลปิน'}{capitalizeFirstLetter(item.artType[lang])}{lang == 'en' && ' Artist'}
                        </Typography>
                    </CardActionArea>
                  </CardContent>
                </Card>
              </Grid>
              ))}
            </Grid>


         
          {
            sampleArr.length > 8 && (
              <div className='col-md-12 d-flex justify-content-center mt-3'>
                <Pagination
                count={count}
                size="large"
                page={pageset}
                color={dark ? "secondary" :"primary"}
                className='draktextpaging'
                onChange={handleChange}
                />
              </div>
            )
          }
        </div>
          )}
        </div>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Art);