import React from 'react'
import pagedetail from '../menulist/Home.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, CardActionArea, CardContent, Grid, Grow } from '@mui/material';

import {
  useParams,
  useHistory
} from "react-router-dom";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import FeedIcon from '@mui/icons-material/Feed';
import PeopleIcon from '@mui/icons-material/People';

import moment from 'moment';
import { Carousel as MobileCarousel } from 'react-responsive-carousel';
import Carousel from 'react-material-ui-carousel'
import CloseIcon from '@mui/icons-material/Close';

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

const Home = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage,
  login, setgreetall, greetall}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    // const [langselect, setLang] = React.useState('en');

    const History = useHistory();
 
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (lang == 'th') {
        setPage('หน้าหลัก')
      } else {
        setPage('Homepage')
      }

      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.onrender.com/tpop/status')
        .then((response) => response.text())
        .then((data) => {
          console.log('backend ready')
          setLoad(false)
        }).catch(() => {
          console.log('fail')
        });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);
    
    // React.useEffect(() => {
    //   setLang(lang)
    // }, [lang]);

    // React.useEffect(() => {
    //   setLang(lang)
    // }, [load]);

    const changep = (artid) => {
      setLoad(true)
      setTimeout(() => History.push('/artist/' + artid), 700)
    }

    React.useEffect(() => {
      const current = document.documentElement.scrollTop
      window.scrollTo(0, document.documentElement.scrollHeight);
      window.scrollTo(0, 0);
      window.scrollTo(0, current);
    }, [dark])
    
   

    if (load) return null
    return ( 
      <>
      {
         width > 900 ? (
           <Carousel interval={8000}>
           {pagedetail[lang].list.map((item, i) => (
             <Card key={"home-" + item.id} className={width > 1400 ? "padcro" : ''} sx={{backgroundColor: (dark ?'#02012b' : '')}}>
                <CardActionArea className='cro-container' onClick={() => changep(item.id)}>
                  <CardMedia src={item.img} component="img" />
                  <Grow in={true} timeout={1000}>
                    <Card className='cro-text' data-aos="zoom-in-down">
                        <CardHeader title={(<h3>{item.artistName}</h3>)} subheader={pagedetail[lang].listforclick} />
                    </Card>
                  </Grow>
                </CardActionArea>
                </Card>
          ))}
      </Carousel>
              ) : (
                <MobileCarousel autoPlay infiniteLoop showArrows showStatus={false} interval={8000}>
                    {pagedetail[lang].list.map((item, i) => (
                      <Card key={"home-" + item.id}>
                          <CardActionArea onClick={() => changep(item.id)}>
                      <CardMedia src={item.img} component="img" />
                      <div data-aos="zoom-in-down" className='text-left pb-4'>
                        <CardHeader title={item.artistName} subheader={pagedetail[lang].listforclick} />
                        </div>
                    </CardActionArea>
                    </Card>
              ))}
          </MobileCarousel>
              )
      }
        <Typography className={'indent mt-4' + (dark ? ' text-light' : '')} dangerouslySetInnerHTML={{ __html: pagedetail[lang].desc }}>
        </Typography>
      <div style={{marginTop: 300, marginBottom:300}} />
        {load == false && (
            <div className='text-center mb-3'>
            <CardHeader data-aos="zoom-in-down" sx={{color: (dark ?'white' : '')}} title={lang == 'th' ? 'ฟีเจอร์ใน T-POP Megaverse Platform' : 'Features of T-POP Megaverse Platform'} />
            <Grid container spacing={2} className='mt-2'>
              <Grid item lg={3}>
                <Card data-aos="fade-right" className='pt-3'>
                  <ViewComfyIcon fontSize='large' />
                  <CardHeader className='mt-4' title={lang == 'th' ? 'คล่องตัว ใช้งานง่าย และสบายตา':'Light-weight, clean and simple for you'} subheader={lang == 'th' ? 'เราได้มอบประสบการณ์การใช้งานที่อ่านง่าย สบายตา และลดการใช้ทรัพยากรบราวเซอร์เกินความจำเป็น':'We bring web experience to be clean and easy to look and used. And also reduce browser resource usaged.'} />
                </Card>
              </Grid>
              <Grid item lg={3}>
                <Card data-aos="fade-right" data-aos-delay={width > 700 ? "500": '0'} className='pt-3'>
                  <SpatialAudioIcon fontSize='large' />
                  <CardHeader className='mt-4' title={lang == 'th' ? 'รวมข้อมูลศิลปินไว้มากที่สุด':'All the most T-POP artists at once'} subheader={lang == 'th' ? 'เราได้รวบรวมข้อมูลและข่าวสารของศิลปิน T-POP ไว้มากที่สุด และคุณสามารถค้นหาแบบ Instant Search โดยระบบจะแสดงผลข้อมูลที่เกี่ยวข้องได้ทันที':'We bring all the most of artist information and announcement update. You also use Instant Search feature to search artist which you want faster.'} />
                </Card>
              </Grid>
              <Grid item lg={3}>
                <Card data-aos="fade-right" data-aos-delay={width > 700 ? "1000": '0'} className='pt-3'>
                  <FeedIcon fontSize='large' />
                  <CardHeader className='mt-4' title={lang == 'th' ? 'จัดอันดับเพลงฮิตประจำสัปดาห์และข่าวสารวงการ T-POP และวงการเพลงจากทั่วโลกแบบเรียลไทม์':'T-POP Weekly Top Songs and Realtime News Update'} subheader={lang == 'th' ? 'รายงาน 50 อันดับเพลงที่มียอดการฟังสูงที่สุดในแต่ละสัปดาห์ รวมทั้งข่าวสารวงการ T-POP และวงการเพลงทั่วทุกมุมโลกแบบเรียลไทม์':'We bring 50 top chart of T-POP songs and news update from T-POP and worldwide music update.'} />
                </Card>
              </Grid>
              <Grid item lg={3}>
                <Card data-aos="fade-right" data-aos-delay={width > 700 ? "1500" : '0'} className='pt-3'>
                  <PeopleIcon fontSize='large' />
                  <CardHeader className='mt-4' title={lang == 'th' ? 'การแลกเปลี่ยนข่าวสารวงการ T-POP แบบไร้พรมแดนด้วย T-POP Community':'Making and changing information about T-POP without borders with T-POP Community'} subheader={lang == 'th' ? 'คุณเองก็มีสิทธิ์ร่วมผลักดันศิลปิน T-POP ที่คุณชื่นชอบได้ด้วยการร่วมแชร์ข่าวสารและพูดคุยและเปลี่ยนความคิดเห็นได้จากทุกมุมโลก ขอให้ทราบว่าทุกบทความหรือการแสดงความคิดเห็นจะถูกตรวจสอบและสั่งปิดกั้นได้ทุกเมื่อ เพื่อรักษาบรรยากาศการแลกเปลี่ยนพูดคุยให้มีความเป็นระเบียบและไม่ขัดต่อนโยบายส่วนรวม':'You too have the right to promote your favorite T-POP artists by sharing news and discussing and changing opinions from everywhere. Please note that every article or comment will be reviewed and blocked at any time. in order to maintain not against public policy'} />
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
      </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);